<template>
  <!-- eslint-disable -->
  <div class="tracker-page" v-loading="loading">
    <div v-if="$root.windowWidth < 768" class="tracker-mobile-breadcrumbs">
      <div class="breadcrumb-container">
        <tracker-breadcrumbs></tracker-breadcrumbs>
      </div>
    </div>

    <mt-datetime-picker
      type="date"
      confirm-text="OK"
      cancel-text="Cancel"
      ref="mobileStartPicker"
      @confirm="handleStartMobileConfirm"
      :value="selectedRange[0]"
      v-if="selectedRange.length"
    ></mt-datetime-picker>

    <mt-datetime-picker
      type="date"
      confirm-text="OK"
      cancel-text="Cancel"
      ref="mobileEndPicker"
      @confirm="handleEndMobileConfirm"
      :value="selectedRange[1]"
      v-if="selectedRange.length"
    ></mt-datetime-picker>

    <el-row class="heading">
      <el-col class="datepicker">
        <el-date-picker
          v-model="selectedRange"
          type="daterange"
          range-separator="To"
          value-format="yyyy-MM-dd"
          format="MM/dd/yyyy"
          :clearable="false"
          align="left"
          :unlink-panels="true"
          :picker-options="desktopPickerOptions"
          v-show="$root.windowWidth > 767"
          size="small"
        ></el-date-picker>
        <div class="mobile-date-range-picker" v-show="$root.windowWidth <= 767">
          <el-input
            readonly
            v-model="humanFormattedRange[0]"
            size="small"
            @focus="openStartMobilePicker"
          ></el-input>
          <span> To </span>
          <el-input
            readonly
            v-model="humanFormattedRange[1]"
            size="small"
            @focus="openEndMobilePicker"
          ></el-input>
        </div>
      </el-col>
      <el-dropdown class="scalepicker" trigger="click" @command="scaleChange">
        <el-button
          type="primary"
          size="small"
          style="height: 32px; margin-left: 0.5rem"
        >
          {{
            selectedScale.charAt(0).toUpperCase() + selectedScale.substr(1)
          }}&nbsp;
          <font-awesome-icon icon="caret-down" full-width></font-awesome-icon>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :disabled="!scales.hourly" command="hourly">
            Hourly
          </el-dropdown-item>
          <el-dropdown-item :disabled="!scales.daily" command="daily">
            Daily
          </el-dropdown-item>
          <el-dropdown-item :disabled="!scales.weekly" command="weekly">
            Weekly
          </el-dropdown-item>
          <el-dropdown-item :disabled="!scales.monthly" command="monthly">
            Monthly
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>

    <tracker-graph
      :data="data"
      :dates="dates"
      :scale="selectedScale"
      :range="selectedRange"
      :tracker-info="trackerInfo"
      :service="service"
      :type="type"
      :loading="loading"
    ></tracker-graph>

    <tracker-stats-card
      v-if="data"
      v-for="item in data"
      :key="item.key"
      :data="item"
      :scale="selectedScale"
      :range="selectedRange"
      :type="type"
      :service="service"
      :tracker-info="trackerInfo"
    ></tracker-stats-card>
  </div>
</template>

<style lang="scss">
.tracker-page {
  .heading {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .datepicker {
      flex: 0;

      .el-range-separator {
        text-transform: uppercase;
        color: #666;
        font-size: 0.8rem;
      }
    }

    .scalepicker {
      flex: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .tracker-page {
    .heading {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .datepicker {
        flex: 1;
        .mobile-date-range-picker {
          display: flex;
          .el-input {
            flex: 1;
            width: auto;

            input {
              padding-right: 0;
              padding-left: 0;
              text-align: center;
              font-size: 16px;
            }

            .el-input__prefix,
            el-input__suffix {
              display: none;
            }
          }
          span {
            flex: 0;
            margin: 0 0.25rem;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            color: #666;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

.tracker-mobile-breadcrumbs {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;

  .breadcrumb-container {
    > div {
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<script>
/* eslint-disable */
import { mapGetters } from "vuex"
import TrackerGraph from "@/components/Charts/TrackerGraph"
import moment from "moment"
import TrackerBreadcrumbs from "@/components/TrackerBreadcrumbs"
import TrackerStatsCard from "@/partials/Tracker/TrackerStatsCard"
import DateTime from "@/mixins/DateTime"

import svg from "@/theme/svg"
import axios from "axios";

export default {
  name: "Tracker",

  mixins: [DateTime],
  components: {
    TrackerStatsCard,
    TrackerGraph,
    TrackerBreadcrumbs,
  },

  props: {},
  data() {
    return {
      seed: 0,
      dates: [],
      clicks: [],
      impressions: [],
      conversions: [],
      data: [],
      selectedRange: [],
      desktopPickerOptions: {
        disabledDate: (time) => {
          return time.getTime() >= parseInt(moment().format("x"))
        },
        firstDayOfWeek: 7,
      },
      selectedScale: "daily",
      cards: [],
      loading: false,
      mounted: false,
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/user",
      companies: "companies/list",
    }),
    humanFormattedRange() {
      if (!this.selectedRange.length) return ["", ""]
      return [
        this.dateTimeFormatString(this.selectedRange[0], "MM/DD/YYYY"),
        this.dateTimeFormatString(this.selectedRange[1], "MM/DD/YYYY"),
      ]
    },
    company() {
      let index = this.$_.findIndex(this.companies, {
        slug: this.$route.params.company,
      })
      if (index === -1) return false
      return this.companies[index]
    },
    trackerInfo() {
      if (!this.company) return false
      return this.company.tracker
    },
    type() {
      if (!this.$route.params.type) return false
      return this.$route.params.type
    },
    service() {
      if (!this.$route.params.service) return false
      let service = this.$route.params.service
      if (service === "leasedlist") {
        service = "emailresultz"
      } else if (service === "houselist") {
        service = "mailchimp"
      } else if (service === "geofencing") {
        service = "simplifi"
      } else if (service === "streaming") {
        service = "tremor"
      }
      return service
    },
    logos() {
      return {
        ts: svg.tropic,
        ca: svg.comet,
      }
    },
    ctr() {
      if (this.clicks.length === 0) return []

      return this.calcCtr(this.clicks, this.impressions)
    },
    scales() {
      let truth = {
        monthly: false,
        weekly: false,
        daily: false,
        hourly: false,
      }

      if (!this.selectedRange.length) return truth
      let start = moment(this.selectedRange[0], "YYYY-MM-DD")
          .hours(0)
          .minutes(0)
          .seconds(0),
        end = moment(this.selectedRange[1], "YYYY-MM-DD")
          .add(1, "days")
          .hours(0)
          .minutes(0)
          .seconds(0),
        duration = moment.duration(end.diff(start)),
        hoursCount = duration.as("hours"),
        daysCount = duration.as("days"),
        weeksCount = duration.as("weeks"),
        monthsCount = duration.as("months")

      let scale = "monthly"

      if (
        (monthsCount > 1 && monthsCount < 61) ||
        (monthsCount < 1 && start.month() !== end.month())
      ) {
        truth["monthly"] = true
      }

      if (
        (weeksCount > 1 && weeksCount < 61) ||
        (weeksCount < 1 && start.week() !== end.week())
      ) {
        truth["weekly"] = true
        scale = "weekly"
      }

      if (hoursCount > 1 && hoursCount < 73) {
        truth["hourly"] = true
        scale = "hourly"
      }

      if (daysCount > 1 && daysCount < 61) {
        truth["daily"] = true
        scale = "daily"
      }

      if (!truth[this.selectedScale]) {
        this.selectedScale = scale
      }

      return truth
    },
    availableServices() {
      if (!this.trackerInfo) return
      if (typeof this.trackerInfo.services !== "object") return []
      return Object.keys(this.trackerInfo.services)
    },
  },
  watch: {
    $route() {
      this.routeChange()
    },
    selectedRange(newRange) {
      if (newRange && newRange.length === 2) {
        localStorage.setItem('selectedRange', JSON.stringify(newRange));
      }
    },
    selectedScale: {
      handler: function (val) {
        if (!this.mounted) return
        if (!this.selectedRange) return

        this.$cookie.set(
          "trackerSettings",
          JSON.stringify({
            start: this.selectedRange[0],
            end: this.selectedRange[1],
            scale: val,
          })
        )

        this.$router.replace({
          name: "tracker",
          params: {
            company: this.$route.params.company,
            type: this.$route.params.type,
            service: this.$route.params.service,
          },
          query: {
            start: this.selectedRange[0],
            end: this.selectedRange[1],
            scale: val,
          },
        })
      },
    },
  },

  methods: {
    routeChange() {
      document.title =
        this.$root.app.name + " - " + this.company.name + " - Tracker"

      // if (!this.company || !this.trackerInfo) {
      //   this.$router.replace("/404")
      // }

      if (
        this.$route.params.type &&
        ["sem", "display", "mail", "tv", "video"].indexOf(
          this.$route.params.type
        ) === -1
      ) {
        this.$router.replace("/404")
      } else {
        if (
          this.$route.params.type === "sem" &&
          this.availableServices.indexOf("google-sem") === -1 &&
          this.availableServices.indexOf("bing-sem") === -1
        ) {
          this.$router.replace("/404")
        } else if (
          this.$route.params.type === "display" &&
          this.availableServices.indexOf("google-display") === -1 &&
          this.availableServices.indexOf("facebook-display") === -1 &&
          this.availableServices.indexOf("simplifi-display") === -1 &&
          this.availableServices.indexOf("agility-display") === -1
        ) {
          this.$router.replace("/404")
        } else if (
          this.$route.params.type === "mail" &&
          this.availableServices.indexOf("siteimpact-mail") === -1 &&
          this.availableServices.indexOf("mailchimp-mail") === -1 &&
          this.availableServices.indexOf("constantcontact-mail") === -1 &&
          this.availableServices.indexOf("emailresultz-mail") === -1
        ) {
          this.$router.replace("/404")
        } else if (
          this.$route.params.type === "tv" &&
          this.availableServices.indexOf("simplifi-tv") === -1 &&
          this.availableServices.indexOf("tremor-tv") === -1 &&
          this.availableServices.indexOf("settop-tv") === -1 &&
          this.availableServices.indexOf("agility-tv") === -1
        ) {
          this.$router.replace("/404")
        } else if (
          this.$route.params.type === "video" &&
          this.availableServices.indexOf("google-video") === -1 &&
          this.availableServices.indexOf("facebook-video") === -1
        ) {
          this.$router.replace("/404")
        }
      }

      if (this.$route.params.service) {
        if (
          this.$route.params.type === "display" &&
          [
            "google",
            "google-remarketing",
            "facebook",
            "facebook-remarketing",
            "simplifi",
            "geofencing",
            "agility",
          ].indexOf(this.$route.params.service) === -1
        ) {
          this.$router.replace("/404")
        } else if (
          this.$route.params.type === "sem" &&
          ["google", "bing"].indexOf(this.$route.params.service) === -1
        ) {
          this.$router.replace("/404")
        } else if (
          this.$route.params.type === "display" &&
          ["google-remarketing", "facebook-remarketing"].indexOf(
            this.$route.params.service
          ) !== -1 &&
          !this.trackerInfo.remarketing
        ) {
          this.$router.replace("/404")
        } else if (
          this.$route.params.type === "mail" &&
          [
            "siteimpact",
            "mailchimp",
            "constantcontact",
            "emailresultz",
            "leasedlist",
            "houselist",
          ].indexOf(this.$route.params.service) === -1
        ) {
          this.$router.replace("/404")
        } else if (
          this.$route.params.type === "tv" &&
          ["simplifi", "tremor", "settop", "geofencing", "streaming", "agility"].indexOf(
            this.$route.params.service
          ) === -1
        ) {
          this.$router.replace("/404")
        } else if (
          this.$route.params.type === "video" &&
          [
            "google",
            "google-remarketing",
            "facebook",
            "facebook-remarketing",
          ].indexOf(this.$route.params.service) === -1
        ) {
          this.$router.replace("/404")
        }
      }

      let truth = false
      if (this.$route.query.start && this.$route.query.end) {
        let start = moment(this.$route.query.start, "YYYY-MM-DD"),
          end = moment(this.$route.query.end, "YYYY-MM-DD")
        if (
          start instanceof moment &&
          start.isValid() &&
          end instanceof moment &&
          end.isValid()
        ) {
          this.selectedRange = [
            start.format("YYYY-MM-DD"),
            end.format("YYYY-MM-DD"),
          ]
          truth = true
        }
      }

      if (!truth) {
        let exist = JSON.parse(this.$cookie.get("trackerSettings"))

        if (exist) {
          this.selectedRange = [exist.start, exist.end]

          this.selectedScale = exist.scale
        } else {
          let services = {}
          if (!this.$route.params.type && !this.$route.params.service) {
            services = this.company.tracker.services
          } else if (this.$route.params.type && !this.$route.params.service) {
            if (this.$route.params.type === "display") {
              if (this.availableServices.indexOf("google-display") !== -1) {
                services["google-display"] =
                  this.trackerInfo.services["google-display"]
              }
              if (this.availableServices.indexOf("facebook-display") !== -1) {
                services["facebook-display"] =
                  this.trackerInfo.services["facebook-display"]
              }
              if (this.availableServices.indexOf("simplifi-display") !== -1) {
                services["simplifi-display"] =
                  this.trackerInfo.services["simplifi-display"]
              }
              if (this.availableServices.indexOf("agility-display") !== -1) {
                services["agility-display"] =
                  this.trackerInfo.services["agility-display"]
              }
            } else if (this.$route.params.type === "sem") {
              if (this.availableServices.indexOf("google-sem") !== -1) {
                services["google-sem"] = this.trackerInfo.services["google-sem"]
              }
              if (this.availableServices.indexOf("bing-sem") !== -1) {
                services["bing-sem"] = this.trackerInfo.services["bing-sem"]
              }
            } else if (this.$route.params.type === "mail") {
              if (this.availableServices.indexOf("siteimpact-mail") !== -1) {
                services["siteimpact-mail"] =
                  this.trackerInfo.services["siteimpact-mail"]
              }
              if (this.availableServices.indexOf("mailchimp-mail") !== -1) {
                services["mailchimp-mail"] =
                  this.trackerInfo.services["mailchimp-mail"]
              }
              if (
                this.availableServices.indexOf("constantcontact-mail") !== -1
              ) {
                services["constantcontact-mail"] =
                  this.trackerInfo.services["constantcontact-mail"]
              }
              if (this.availableServices.indexOf("emailresultz-mail") !== -1) {
                services["emailresultz-mail"] =
                  this.trackerInfo.services["emailresultz-mail"]
              }
            } else if (this.$route.params.type === "tv") {
              if (this.availableServices.indexOf("simplifi-tv") !== -1) {
                services["simplifi-tv"] =
                  this.trackerInfo.services["simplifi-tv"]
              }
              if (this.availableServices.indexOf("tremor-tv") !== -1) {
                services["tremor-tv"] = this.trackerInfo.services["tremor-tv"]
              }
              if (this.availableServices.indexOf("agility-tv") !== -1) {
                services["agility-tv"] = this.trackerInfo.services["agility-tv"]
              }
              if (this.availableServices.indexOf("settop-tv") !== -1) {
                services["settop-tv"] = this.trackerInfo.services["settop-tv"]
              }
            } else if (this.$route.params.type === "video") {
              if (this.availableServices.indexOf("google-video") !== -1) {
                services["google-video"] =
                  this.trackerInfo.services["google-video"]
              }
              if (this.availableServices.indexOf("facebook-video") !== -1) {
                services["facebook-video"] =
                  this.trackerInfo.services["facebook-video"]
              }
            }
          } else if (this.$route.params.type && this.$route.params.service) {
            let temp =
              this.$route.params.service + "-" + this.$route.params.type
            services[temp] = this.trackerInfo.services[temp]

          }

          let end = this.$_.head(this.$_.orderBy(services, "end", "desc"))


          if (end) {
            this.selectedRange = [
              moment(end.end * 1000)
                .subtract(29, "days")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .format("YYYY-MM-DD"),
              moment(end.end * 1000)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .format("YYYY-MM-DD"),
            ]
          } else {
            this.selectedRange = [
              moment()
                .subtract(30, "days")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .format("YYYY-MM-DD"),
              moment()
                .subtract(1, "days")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .format("YYYY-MM-DD"),
            ]
          }
        }
      }
      if (this.$route.query.scale) {
        if (
          ["hourly", "daily", "weekly", "monthly"].indexOf(
            this.$route.query.scale
          ) !== -1
        ) {
          this.selectedScale = this.$route.query.scale
        }
      }

      this.getGraphData()
    },
    scaleChange(scale) {
      this.selectedScale = scale
    },
    getGraphData() {
      this.loading = true
      let uri =
        "/tracker/" +
        this.company.slug +
        "/data?start=" +
        this.selectedRange[0] +
        "&end=" +
        this.selectedRange[1] +
        "&scale=" +
        this.selectedScale

      if (this.type && !this.service) {
        uri += "&scope=services&scopeValue=" + this.type
      } else if (this.type && this.service) {
        let service = this.service
        if (this.service === "leasedlist") {
          service = "emailresultz"
        } else if (this.service === "houselist") {
          service = "mailchimp"
        } else if (this.service === "geofencing") {
          service = "simplifi"
        } else if (this.service === "streaming") {
          service = "tremor"
        }
        uri += "&scope=service&scopeValue=" + service + "-" + this.type
      }

      this.axios
        .get(uri)
        .then((response) => {
          this.dates = response.data.data.dates

          this.data = this.$_.map(response.data.data.data, (item, key) => {
            let data = {
              clicks: [],
              impressions: [],
              conversions: [],
              opens: [],
            }

            this.$_.each(item.data, function (item) {
              data["clicks"].push(parseInt(item.clicks))
              data["impressions"].push(parseInt(item.impressions))
              data["conversions"].push(parseInt(item.conversions))
              data["opens"].push(parseInt(item.opens))
            })

            data.ctr = this.calcCtr(data.clicks, data.impressions)
            data.cr = this.calcCtr(data.conversions, data.impressions)

            return {
              key: key,
              name: item.name,
              from: item.from,
              custom_name: item.custom_name ? item.custom_name : false,
              start: item.start ? item.start : null,
              end: item.end ? item.end : null,
              active: item.active,
              asset: item.asset ? item.asset : null,
              conversions: item.conversions,
              conversions_label: item.conversions_label,
              conversions_abbreviation: item.conversions_abbreviation,
              graph_type: item.graph_type,
              data_type: item.data_type,
              data: data,
            }
          })

          this.loading = false
        })
        .catch((response) => {
          this.$root.globalRequestError(response)
          this.loading = false
        })
    },
    calcCtr(clicks, impressions) {
      let temp = []
      clicks.forEach((value, index) => {
        if (impressions[index] > 0) {
          temp.push((value / impressions[index]) * 100)
        } else {
          temp.push(0)
        }
      })
      return temp
    },
    openStartMobilePicker() {
      this.$refs.mobileStartPicker.open()
    },
    handleStartMobileConfirm(val) {
      val = moment(val)
      let end = moment(this.selectedRange[1])
      if (val.diff(end, "days") < 0) {
        this.$router.replace({
          name: "tracker",
          params: {
            company: this.$route.params.company,
            type: this.$route.params.type,
            service: this.$route.params.service,
          },
          query: {
            start: val.format("YYYY-MM-DD"),
            end: this.selectedRange[1],
            scale: this.selectedScale,
          },
        })
      } else {
        this.$message.warning("Start Date must come before End Date.")
        return
      }
    },
    openEndMobilePicker() {
      this.$refs.mobileEndPicker.open()
    },
    handleEndMobileConfirm(val) {
      val = moment(val)
      let start = moment(this.selectedRange[0])

      if (val.diff(start, "days") > 0) {
        this.$router.replace({
          name: "tracker",
          params: {
            company: this.$route.params.company,
            type: this.$route.params.type,
            service: this.$route.params.service,
          },
          query: {
            start: this.selectedRange[0],
            end: val.format("YYYY-MM-DD"),
            scale: this.selectedScale,
          },
        })
      } else {
        this.$message.warning("End Date must come after Start Date.")
        return
      }
    },
  },

  created() {
    this.routeChange()
    this.$nextTick(() => {
      this.mounted = true
    })
  },
  mounted() {},
}
</script>
